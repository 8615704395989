<template>
  <div class="business-details">
    <div v-if="!editing" class="header">
      <div class="expanded-about-heading">About</div>
      <div v-if="editable" @click="onClickEdit" style="margin-right: -8px">
        <ellipse-icon
          class="button_card_icon"
          :width="`45px`"
          :height="`45px`"
          style="cursor: pointer"
        >
          <v-icon style="color: #582963" medium>edit</v-icon>
        </ellipse-icon>
      </div>
    </div>
    <div v-if="expandable && !expanded && !editing" class="section">
      <p class="expanded-about">
        <truncate-hovering
          :displayTextOnHover="false"
          :text="about"
          :length="1000"
        />
      </p>
      <hr />
      <div class="expand-button" @click="onClickExpand">
        <span
          >Show all details
          <v-icon style="color: #473068">mdi-arrow-right</v-icon></span
        >
      </div>
    </div>
    <div v-if="!editing && (expanded || !expandable)">
      <div class="details">
        <p class="expanded-about">{{ about }}</p>
        <div class="info-fields">
          <span><strong>Name:</strong>{{ " " }}{{ name }}</span>
          <br />
          <span v-if="website"
            ><strong>Website:</strong>{{ " " }}{{ website }}</span
          >
          <br v-if="website" />
          <span v-if="registrationNumber">
            <strong>Registration Number:</strong>{{ " "
            }}{{ registrationNumber }}</span
          >
          <br v-if="registrationNumber" />
          <span v-if="establishedYear">
            <strong>Established Year:</strong>{{ " " }}{{ establishedYear }}
          </span>
          <br v-if="establishedYear" />
          <span v-if="addressLine1">
            <strong>Address:</strong>
            <span>
              {{ " " }}
              {{ addressLine1 }}, {{ addressLine2 }}, {{ city }}, {{ state }}
              {{ " " }} {{ postalCode }}, {{ country }}
            </span>
          </span>
          <br v-if="addressLine1" />
          <span v-if="email"><strong>Email:</strong>{{ " " }}{{ email }}</span>
          <br v-if="email" />
          <span v-if="mobile">
            <strong>Contact Number:</strong>
            {{ " " }}{{ mobile }}
          </span>
          <br v-if="mobile" />
          <br />
          <span v-if="vision"><strong>Company Vision:</strong></span>
          <br v-if="vision" />
          <span>{{ vision }}</span>
          <br />
          <br />
          <span v-if="mission"><strong>Company Mission:</strong></span>
          <br v-if="mission" />
          <span>{{ mission }}</span>
        </div>

        <div v-if="expanded && expandable">
          <hr />
          <div class="expand-button" @click="onClickExpand">
            <span
              ><v-icon style="color: #473068">mdi-arrow-left</v-icon
              >{{ " " }}Show less
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editing">
      <v-form ref="aboutForm">
        <div class="info-fields">
          <p class="field text-area">
            <strong>About:</strong>
            <v-textarea
              v-model="details.about"
              label=""
              class="text-field"
              :rules="rules.about"
              outlined
            />
          </p>
          <p class="field">
            <strong>Website:</strong>
            <v-text-field
              v-model="details.website"
              type="text"
              label=""
              class="text-field"
              :rules="rules.website"
              outlined
              dense
            />
          </p>
          <p class="field">
            <strong>Registration Number:</strong>
            <v-text-field
              v-model="details.registrationNumber"
              type="text"
              label=""
              class="text-field"
              :rules="rules.registrationNumber"
              outlined
              dense
            />
          </p>
          <p class="field">
            <strong>Established Year:</strong>
            <v-text-field
              v-model="details.establishedYear"
              type="number"
              label=""
              class="text-field"
              :rules="rules.establishedYear"
              v-on:keypress="allowOnlyPositiveNumbers"
              outlined
              dense
            />
          </p>
          <v-form ref="addressForm">
            <p class="field">
              <strong>Address Line 1:</strong>
              <v-text-field
                v-model="details.addressLine1"
                type="text"
                label=""
                class="text-field"
                :rules="rules.addressLine1"
                @input="onAddressInput"
                outlined
                dense
              />
            </p>
            <p class="field">
              <strong>Address Line 2:</strong>
              <v-text-field
                v-model="details.addressLine2"
                type="text"
                label=""
                class="text-field"
                :rules="rules.addressLine2"
                @input="onAddressInput"
                outlined
                dense
              />
            </p>
            <p class="field">
              <strong>City:</strong>
              <v-text-field
                v-model="details.city"
                type="text"
                label=""
                class="text-field"
                :rules="rules.city"
                @input="onAddressInput"
                outlined
                dense
              />
            </p>
            <p class="field">
              <strong>State:</strong>
              <v-text-field
                v-model="details.state"
                type="text"
                label=""
                class="text-field"
                :rules="rules.state"
                @input="onAddressInput"
                outlined
                dense
              />
            </p>
            <p class="field">
              <strong>Postal Code:</strong>
              <v-text-field
                v-model="details.postalCode"
                type="text"
                label=""
                class="text-field"
                :rules="rules.postalCode"
                @input="onAddressInput"
                outlined
                dense
              />
            </p>
            <p class="field">
              <strong>Country:</strong>
              <v-autocomplete
                v-model="details.country"
                :items="countries"
                :rules="rules.country"
                @input="onAddressInput"
                outlined
                clearable
                dense
              >
                <template v-slot:item="{ item }">
                  <span>{{ item }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ item }}</span>
                </template>
              </v-autocomplete>
            </p>
          </v-form>
          <p class="field">
            <strong>Email:</strong>
            <v-text-field
              v-model="details.email"
              type="text"
              label=""
              class="text-field"
              :rules="rules.email"
              outlined
              dense
            />
          </p>
          <div class="field">
            <strong>Contact Number:</strong>
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: flex-start;
              "
            >
              <vue-country-code
                style="height: 40px"
                @onSelect="userNumberSelect"
                :defaultCountry="defaultCountry"
              />
              <v-text-field
                v-model="details.mobile"
                :prefix="countryCode + ' '"
                type="number"
                label=""
                class="text-field phone-number-input"
                style="margin-left: 10px"
                :rules="rules.mobile"
                v-on:keypress="allowOnlyPositiveNumbers"
                hide-spin-buttons
                outlined
                dense
              />
            </div>
          </div>
          <p class="field text-area">
            <strong>Company Vision:</strong>
            <v-textarea
              v-model="details.vision"
              label=""
              class="text-field"
              :rules="rules.vision"
              outlined
            />
          </p>
          <p class="field text-area">
            <strong>Company Mission:</strong>
            <v-textarea
              v-model="details.mission"
              label=""
              class="text-field"
              :rules="rules.mission"
              outlined
            />
          </p>
        </div>

        <div v-if="editing" class="button-container">
          <ui-button
            class="primary-outlined"
            @click="onClickCancel"
            title="Cancel"
            :customStyles="{
              width: '50px',
            }"
          />
          <ui-button
            class="primary"
            @click="onClickSave"
            title="Save"
            :customStyles="{
              width: '50px',
            }"
          />
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import countryList from "country-list";
import countryCodesList from "country-codes-list";
import VueCountryCode from "vue-country-code";
export default {
  name: "About",
  components: {
    VueCountryCode,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: [Boolean, Number],
      default: false,
    },
    about: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    registrationNumber: {
      type: String,
      default: "",
    },
    website: {
      type: String,
      default: "",
    },
    establishedYear: {
      type: [Number, String],
      default: "",
    },
    addressLine1: {
      type: String,
      default: "",
    },
    addressLine2: {
      type: String,
      default: "",
    },
    city: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    postalCode: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    mobile: {
      type: String,
      default: "",
    },
    vision: {
      type: String,
      default: "",
    },
    mission: {
      type: String,
      default: "",
    },
  },
  watch: {
    $props: {
      handler() {
        this.setValuesFromProps();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      editing: false,
      countryCode: "",
      defaultCountry: "AF",
      details: {
        about: "",
        website: "",
        registrationNumber: "",
        establishedYear: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: null,
        email: "",
        mobile: "",
        vision: "",
        mission: "",
      },
      rules: {
        about: [
          (value) =>
            !value ||
            value.length <= 10000 ||
            "About must be less than 10000 characters.",
        ],
        website: [
          (value) =>
            !value ||
            value.length <= 255 ||
            "Website must be less than 255 characters.",
          (value) =>
            !value ||
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
              value
            ) ||
            "Invalid website format.",
        ],
        registrationNumber: [
          (value) =>
            !value ||
            value.length <= 255 ||
            "Registration number must be less than 255 characters.",
        ],
        establishedCity: [
          (value) =>
            !value ||
            value.length <= 255 ||
            "Established city must be less than 255 characters.",
        ],
        establishedCountry: [
          (value) =>
            !value ||
            value.length <= 255 ||
            "Established country must be less than 255 characters.",
        ],
        establishedYear: [
          (value) =>
            !value ||
            Number.isInteger(Number(value)) ||
            "Established year must be an integer.",
          (value) =>
            !value || Number(value) >= 0 || "Negative numbers not allowed.",
        ],
        vision: [
          (value) =>
            !value ||
            value.length <= 1000 ||
            "Vision must be less than 1000 characters.",
        ],
        mission: [
          (value) =>
            !value ||
            value.length <= 1000 ||
            "Mission must be less than 1000 characters.",
        ],
        email: [
          (value) =>
            !value ||
            value.length <= 255 ||
            "Email must be less than 255 characters.",
          (value) =>
            !value ||
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
            "Invalid email format.",
        ],
        mobile: [
          (value) =>
            !value ||
            value.length + this.countryCode.length + 1 >= 3 ||
            "Contact number must be at least 3 characters.",
          (value) =>
            !value ||
            value.length + this.countryCode.length + 1 <= 25 ||
            "Contact number must be less than 25 characters.",
        ],
        addressLine1: [
          (value) => {
            if (
              this.details.addressLine2 ||
              this.details.city ||
              this.details.state ||
              this.details.postalCode ||
              this.details.country
            ) {
              if (!value) return "Address line 1 is required.";
            }
            return (
              !value ||
              value.length <= 255 ||
              "Address line 1 must be less than 255 characters."
            );
          },
        ],
        addressLine2: [
          (value) => {
            if (
              this.details.addressLine1 ||
              this.details.city ||
              this.details.state ||
              this.details.postalCode ||
              this.details.country
            ) {
              if (!value) return "Address line 2 is required.";
            }
            return (
              !value ||
              value.length <= 255 ||
              "Address line 2 must be less than 255 characters."
            );
          },
        ],
        city: [
          (value) => {
            if (
              this.details.addressLine1 ||
              this.details.addressLine2 ||
              this.details.state ||
              this.details.postalCode ||
              this.details.country
            ) {
              if (!value) return "City is required.";
            }
            return (
              !value ||
              value.length <= 255 ||
              "City must be less than 255 characters."
            );
          },
        ],
        state: [
          (value) => {
            if (
              this.details.addressLine1 ||
              this.details.addressLine2 ||
              this.details.city ||
              this.details.postalCode ||
              this.details.country
            ) {
              if (!value) return "State is required.";
            }
            return (
              !value ||
              value.length <= 255 ||
              "State must be less than 255 characters."
            );
          },
        ],
        postalCode: [
          (value) => {
            if (
              this.details.addressLine1 ||
              this.details.addressLine2 ||
              this.details.city ||
              this.details.state ||
              this.details.country
            ) {
              if (!value) return "Postal Code is required.";
            }
            return (
              !value ||
              value.length <= 200 ||
              "Postal Code must be less than 200 characters."
            );
          },
        ],
        country: [
          (value) => {
            if (
              this.details.addressLine1 ||
              this.details.addressLine2 ||
              this.details.city ||
              this.details.state ||
              this.details.postalCode
            ) {
              if (!value) return "Country is required.";
            }
            return (
              !value ||
              value.length <= 200 ||
              "Country must be less than 200 characters."
            );
          },
        ],
      },
    };
  },
  computed: {
    countries() {
      return countryList.getNames();
    },
  },
  methods: {
    allowOnlyPositiveNumbers(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    userNumberSelect({ name, iso2, dialCode }) {
      this.countryCode = "+" + dialCode;
    },
    onAddressInput() {
      this.$refs.addressForm.validate();
    },
    onClickExpand() {
      this.$emit("expand");
    },
    onClickEdit() {
      if (this.isVerified) {
        alert(
          "Editing these details will cause your profile to become unverified and it will affect all transactions associated with this profile in published Digital Product Passports.\n\nPlease request for verification again after you have edited the details."
        );
      }
      this.editing = true;
    },
    onClickEditCancel() {
      this.editing = false;
    },
    onClickSave() {
      if (
        !this.$refs.aboutForm.validate() ||
        !this.$refs.addressForm.validate()
      ) {
        return;
      }
      this.$emit("save", {
        ...this.details,
        mobile: this.details.mobile
          ? this.countryCode + " " + this.details.mobile
          : "",
      });
      this.editing = false;
    },
    onClickCancel() {
      this.setValuesFromProps();
      this.editing = false;
    },
    setValuesFromProps() {
      const splittedPhoneNumber = this.mobile?.split(" ") ?? [""];
      if (splittedPhoneNumber.length === 2) {
        this.countryCode = splittedPhoneNumber[0];
        const countryCodes = countryCodesList.customList(
          "countryCode",
          "+{countryCallingCode}"
        );
        const list = Object.entries(countryCodes);
        const selectedCountry = list.find(
          (country) => country[1] == splittedPhoneNumber[0]
        );
        this.defaultCountry =
          selectedCountry && selectedCountry.length ? selectedCountry[0] : "AF";
      }
      this.details = {
        about: this.about,
        website: this.website,
        registrationNumber: this.registrationNumber,
        establishedYear: this.establishedYear,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country || null,
        email: this.email,
        mobile: splittedPhoneNumber[splittedPhoneNumber.length - 1],
        vision: this.vision,
        mission: this.mission,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.business-details {
  font-family: Arial, sans-serif;
  padding: 10px 20px 10px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: #473068;
  background-color: #ffffff;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.about-heading {
  font-weight: bold;
  color: #473068;
  font-size: 18px;
}

.info-fields {
  margin-bottom: 10px;
}

.expanded-about-heading {
  font-weight: bold;
  font-size: 24px;
}

.expanded-about {
  color: #473068;
  font-size: 14px;
  margin: 15px 0px;
}

.edit-input,
.edit-textarea {
  color: #473068;
}

.expand-button {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.small-btn {
  /* Define styles for small buttons */
}

h2 {
  color: #442d64;
  font-size: 24px;
  margin-bottom: 15px;
}

.section {
  text-align: left;
}

p {
  line-height: 1.6;
  color: #442d64;
  margin: 0;
}

strong {
  color: #442d64;
}

.btn_style {
  color: #fff !important;
  background: #442d64;
  border-radius: 3px;
  height: 30px;
  padding: 0 12px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.btn_style:hover {
  background: #341f4e;
}

.details {
  margin-top: 10px;
}

.edit-form {
  margin-top: 20px;
  text-align: center;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.md-button.primary-outlined.md-theme-default.primary {
  background-color: unset !important;
  border-color: unset !important;
}

.v-select::v-deep .v-select__selections {
  color: #442d64 !important;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #442d64;
}

.text-field::v-deep .v-label--active {
  margin-left: -12px;
  margin-top: -3px;
}
.text-area::v-deep .v-text-field--outlined .v-label--active {
  margin-left: -22px;
  margin-top: -3px;
}
.text-area::v-deep .v-input__slot {
  margin-bottom: 4px;
}
.text-area::v-deep .v-text-field__details {
  margin-bottom: 0px;
}
.text-area::v-deep .v-label {
  margin-top: -9px;
}
.text-area::v-deep textarea {
  color: #473068 !important;
}
.v-input--is-focused::v-deep fieldset,
.v-input--has-state::v-deep fieldset {
  border: 2px solid #473068 !important;
}
.text-field::v-deep .v-messages__message {
  margin-left: -12px;
  color: #473068;
}
.text-field::v-deep .error--text .v-messages__message {
  color: $danger !important;
}
.text-field::v-deep input,
.text-field::v-deep label {
  color: #473068 !important;
}
.v-text-field--outlined::v-deep fieldset {
  border: 1px solid #ccc;
  color: #473068;
}
.phone-number-input::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.phone-number-input::v-deep input::-webkit-outer-spin-button,
.phone-number-input::v-deep input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
